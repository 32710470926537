<template>
  <div class="watchlist w-full pb-10">
    <div class="collection-banner-container" style="background: #9D3E4A">
      <Banner
        title="Favorites"
        subtitle="Your Favorite Products"
        :more-info="false"
      >
        <template #logo>
          <svg
            class="w-24 h-24"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              fill="#9D3E4A"
              d="M13.75 3.75c-1.341 0-2.91 1.52-3.75 2.5-.84-.98-2.409-2.5-3.75-2.5-2.374 0-3.75 1.852-3.75 4.209 0 3.916 7.5 8.291 7.5 8.291s7.5-4.375 7.5-8.125c0-2.357-1.376-4.375-3.75-4.375z"
              opacity=".3"
            />
            <path
              fill="#9D3E4A"
              fill-rule="evenodd"
              d="M10 6.25c-.84-.98-2.409-2.5-3.75-2.5-2.374 0-3.75 1.852-3.75 4.209 0 3.916 7.5 8.291 7.5 8.291v-10z"
              clip-rule="evenodd"
            />
          </svg>
        </template>
        <template #header>
          <ol role="list" class="breadcrumb">
            <li>
              <div class="breadcrumb-item">
                <router-link to="/">Home</router-link>
              </div>  
            </li>
            <li>
              <div class="breadcrumb-item">
                <span>/&nbsp;</span><a href="#">Favorites</a>
              </div>
            </li>
          </ol>
        </template>
      </Banner>
    </div>
    <div class="w-full py-10 max-container relative">
      <MemberInfoCard
        v-if="!isLoggedIn"
        title="Keep track of your favorite products"
        description="This helps to make sure you don't forget about a product you want to view later."
      />
      <!-- Listing -->
      <div
        class="flex flex-col items-center w-full py-24"
        v-if="!watchlist.loading && !watchlist?.data?.data?.length"
        :class="!isLoggedIn ? 'blurry-content' : ''"
      >
        <img
          class="inline-block w-24 h-24 mb-5"
          src="/images/watchlist.svg"
          alt="Watchlist"
        />
        <p class="text-brand-secondary text-base font-medium mb-2">
          There’s nothing in your favorites
        </p>
        <span class="text-brand-body text-opacity-50 text-md"
          >It's so lonely in here. Add something you love!</span
        >
      </div>
      <ListLoaderWrapper :isLoading="watchlist.loading" v-if="isLoggedIn" />
      <ItemsList
        :items="watchlist"
        @removeItem="removeItem"
        :filter="[]"
        v-if="isLoggedIn"
      />
      <ListLoaderWrapper
        class="mt-5"
        :isLoading="watchlist.loadingScroll"
        v-if="isLoggedIn"
      />
    </div>
  </div>
</template>

<script>
import Banner from "../common/Banner.vue";
import ItemsList from "@/views/pages/common/ItemsList.vue";
import Watchlist from "@/modules/Watchlist";
import ListLoaderWrapper from "@/components/loaders/ListLoaderWrapper.vue";
import MemberInfoCard from "@/components/MemberInfoCard.vue";
import { computed, watch } from "vue";
import { useStore } from "vuex";

export default {
  name: "Watchlist",
  components: {
    Banner,
    ItemsList,
    ListLoaderWrapper,
    MemberInfoCard,
  },
  beforeMount() {
    this.$store.dispatch("getCart", { ax: null });
  },
  methods: {
    removeItem(event) {
      this.watchlist.data.data = this.watchlist.data.data.filter(
        (element) => element.id != event.id
      );
    },
  },
  setup() {
    const { watchlist, getWatchlist, isLoggedIn } = Watchlist();
    getWatchlist();

    const store = useStore();

    const currentShipping = computed(() => store.getters.getSelectedAddress);

    watch(currentShipping, () => {
      if(store.getters.getAddressChange) {
        store.dispatch("setAddressChange", false);
        getWatchlist();
      }
    });

    return {
      // items,
      watchlist,
      isLoggedIn,
    };
  },
};
</script>

<style lang="scss" scoped>
.watchlist-banner-container {
  @apply flex min-w-full -mx-8 -mt-7 px-10 pt-10 pr-24 pb-16 relative;
}
</style>
