import { inject, onMounted, onUnmounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { store } from "../store";
import Segments from "./Segments";
const { ProductListViewedNonLoggedIn } = Segments();

export default function Categories() {
  const axios = inject("axios");
  const route = useRoute();

  const categories = reactive({
    loading: false,
    data: [],
    error: "",
  });

  const getCategories = async (val = null) => {
    let filter = val ? val.value.map((c) => c.slug) : null;
    categories.loading = true;
    axios.api
      .get("/categories", {
        params: {
          brand: filter,
        },
      })
      .then((resp) => {
        if (resp.status) {
          let data = resp.data.data;
          if (store.getters.cart.price_list_id == "33") {
            categories.data = data;
          } else {
            categories.data = data.filter((e) => !e.name.includes("GGG"));
          }
        } else {
          categories.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (categories.loading = false));
  };

  const topSellProducts = reactive({
    loading: false,
    data: [],
    error: "",
  });

  const getTopSellProducts = async () => {
    if (axios.isLoggedIn) {
      topSellProducts.loading = true;
      axios.authApi
        .get("/top-sell-product")
        .then((resp) => {
          if (resp.status) {
            topSellProducts.data = resp.data.data;

            // Fire Segment Event
            ProductListViewedNonLoggedIn(route, topSellProducts.data, 'trending', 1, 'home');
          } else {
            topSellProducts.error = resp.data.error;
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .finally(() => (topSellProducts.loading = false));
    }
  };
  const banner = reactive({
    loading: false,
    data: [],
    name: "",
    image: "",
    error: "",
  });

  const getBanner = async () => {
    banner.loading = true;
    axios.api
      .get("/collection-details?category_slug=" + route.params.slug)
      .then((resp) => {
        if (resp.status) {
          banner.data = resp.data.data;
        } else {
          banner.error = resp.data.error;
        }
      })
      .catch(function(error) {
        console.log(error);
      })
      .finally(() => (banner.loading = false));
  };
  var page = 1;
  let sort = "";
  let stock = false;

  const category = reactive({
    loading: false,
    data: [],
    error: "",
    onScrollLoading: false,
  });

  async function getData(load) {
    let d = {};
    const brand = store.getters.getSelected.map((r) => r.id);
    const price = store.getters.getPriceCheck ? store.getters.getPriceRange : 0;
    let dataToPost = {
      page,
      stock,
      sort,
      brand,
      min: 0,
      max: price,
    };
    category[load] = true;
    if (axios.isLoggedIn) {
      await axios.authApi
        .get("/aCollection?category_slug=" + route.params.slug, {
          params: dataToPost,
        })
        .then((res) => {
          d = res.data.data;
          if (res.data.filterOptions.length > 0){
            store.dispatch("updateBrandFilter", res.data.filterOptions);
          }
        });
    } else {
      await axios.api
        .get("/collection?category_slug=" + route.params.slug, {
          params: dataToPost,
        })
        .then((res) => {
          d = res.data.data;
          if (res.data.filterOptions)
            store.dispatch("updateBrandFilter", res.data.filterOptions.filter(i => !i.login_required));
        });
    }
    category[load] = false;
    return d;
  }

  const reset = () => {
    category.data = [];
    page = 1;
  };
  async function onSortChanged(v) {
    reset();
    sort = v;
    category.data = await getData("loading");
  }

  async function onStockChanged(v) {
    reset();
    stock = v;
    // maxPrice(stock);
    store.dispatch("updateBrandFilter", []);
    store.dispatch("removeOption", "clear");
  }

  async function onFilterChanged(v) {
    reset();
    const brand = store.getters.getSelected.map((r) => r.id);
    maxPrice(stock, brand);
    category.data = await getData("loading");
  }
  async function onPriceRangeChanged(v) {
    reset();
    category.data = await getData("loading");
    // store.dispatch("removeOption",'clear');
  }

  async function maxPrice(stock = false, brand = []) {
    await axios.authApi
      .get("/max-price?category_slug=" + route.params.slug, {
        params: {
          stock: stock,
          brand: brand,
        },
      })
      .then((res) => {
        if (res.data.status) {
          store.dispatch("setPriceRange", Number(res.data.data.max_price));
          store.dispatch("setMaxPrice", Number(res.data.data.max_price));
          store.dispatch("setPriceCheck", false);
        }
      });
  }

  async function handleScroll() {
    const itemsContainer = document.querySelector(".items-listing-container");
    if (!itemsContainer) {
      return;
    }
    const canLoadMore = page < category.data.last_page;
    const elemScrollHeight = itemsContainer.scrollHeight;
    const elemScrollLoadHeight = elemScrollHeight - elemScrollHeight * 0.5;
    const shouldLoadMore =
      window.pageYOffset + 200 > elemScrollLoadHeight ||
      elemScrollHeight < window.innerHeight ||
      window.pageYOffset + elemScrollLoadHeight < window.innerHeight;
    if (canLoadMore && shouldLoadMore && !category.isLoadingScrollData) {
      page++;
      category.isLoadingScrollData = true;
      const res = await getData("onScrollLoading");
      category.data.current_page = page;
      category.data.data?.push(...res.data);
      category.isLoadingScrollData = false;
    }
  }

  // this will register the event when the component is mounted on the DOM
  onMounted(() => window.addEventListener("scroll", handleScroll));

  // We then unregister the listener when the component is removed from the DOM
  onUnmounted(() => window.removeEventListener("scroll", handleScroll));

  return {
    isLoggedIn: !!localStorage.getItem('LoggedUser'),
    categories,
    getCategories,
    topSellProducts,
    getTopSellProducts,
    banner,
    getBanner,
    category,
    onSortChanged,
    onStockChanged,
    onFilterChanged,
    onPriceRangeChanged,
    maxPrice,
  };
}
